// in posts.js
import * as React from "react";
import { List, Datagrid, TextField, ShowButton, Filter, TextInput, Pagination, TopToolbar, CreateButton, DeleteButton, EditButton, SelectField, NumberField, BooleanField } from 'react-admin';

const SubscriptionPlanPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const SubscriptionPlanActions = (props) => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const SubscriptionPlansFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Identifiant" source="id" alwaysOn />
        <TextInput label="Nom" source="name" alwaysOn />
        <TextInput label="Description" source="description" alwaysOn />
    </Filter>
);

export const SubscriptionPlansList = (props) => (
    <List {...props} pagination={<SubscriptionPlanPagination />} title="Motifs Préfectures" actions={<SubscriptionPlanActions />} filters={<SubscriptionPlansFilter />}>
        <Datagrid>
            <TextField label="Identifiant" source="id" />
            <TextField label="Pays" source="subscriptionCountry" sortable={false} />
            <TextField label="Monnaie" source="currency" />
            <TextField label="Symbole de la monnaie" source="currencySymbol" />
            <NumberField label="Montant période d'essai" source="trialAmount" />
            <NumberField label="Montant paiement récurrent / mois" source="reccuringAmountMonth" />
            <NumberField label="Montant paiement récurrent / an" source="reccuringAmountYear" />
            <NumberField label="Montant paiement récurrent / an sur un mois" source="reccurringAmountYearMonthDisplay" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);