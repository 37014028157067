import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';

const jsonServerProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            sort: field,
            order: order,
            start: (page - 1) * perPage,
            take: perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then((data) => {
            const { headers, json } = data;
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: json,
                total: parseInt(
                    headers.get('x-total-count').split('/').pop(),
                    10
                ),
            };
        });
    },

    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        }));
    },

    getCustom: (resource, params) => {
        if (params && params.id) {
            return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
                data: json,
            }));
        } else if (params && params.days != null) {
            const query = {
                days: params.days,
            };
            return httpClient(`${apiUrl}/${resource}?${stringify(query)}`).then(({ json }) => ({
                data: json,
            }));
        }
        return httpClient(`${apiUrl}/${resource}`).then(({ json }) => ({
            data: json,
        }));
    },
    getFile: (resource, accept) => {
        const options = {}
        options.headers = new Headers({ Accept: accept });
        const auth = JSON.parse(localStorage.getItem('auth'));
        const token = auth && auth.token ? auth.token : '';
        options.headers.set('Authorization', `Bearer ${token}`);

        const requestHeaders = createHeadersFromOptions(options);

        return fetch(`${apiUrl}/${resource}`, { ...options, headers: requestHeaders })
            .then(async response => {
                return Promise.resolve({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: await response.blob(),
                })
            });
        // response.text().then(text => ({
        //     status: response.status,
        //     statusText: response.statusText,
        //     headers: response.headers,
        //     body: text,
        // }))

        // .then(({ status, statusText, headers, body }) => {
        //     let json;
        //     try {
        //         json = JSON.parse(body);
        //     } catch (e) {
        //         // not json, no big deal
        //     }
        //     if (status < 200 || status >= 300) {
        //         return Promise.reject("error");
        //     }
        //     return Promise.resolve({ status, headers, body, json });
        // });
    },
    postCustom: (resource, data) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({ json }) => ({
            data: json,
        })),
    getMany: (resource, params) => {
        if (params.ids && params.ids.length === 1) {
            if (typeof params.ids[0] == "number") {
                return httpClient(`${apiUrl}/${resource}/${params.ids[0]}`).then(({ json }) => ({
                    data: [json],
                }));
            } else {
                return httpClient(`${apiUrl}/${resource}/${params.ids[0].id}`).then(({ json }) => ({
                    data: [json],
                }));
            }

        }
        const query = {
            ids: params.ids,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            sort: field,
            order: order,
            start: (page - 1) * perPage,
            take: page * perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            // if (!headers.has('x-total-count')) {
            //     throw new Error(
            //         'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
            //     );
            // }
            return {
                data: json,
                total: json.length
                // parseInt(
                //     headers.get('x-total-count').split('/').pop(),
                //     10
                // ),
            };
        });
    },

    customUpdate: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
});

export const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const auth = JSON.parse(localStorage.getItem('auth'));
    const token = auth && auth.token ? auth.token : '';
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('Access-Control-Expose-Headers', 'x-total-count');
    return fetchUtils.fetchJson(url, options);
}

export const createHeadersFromOptions = (options) => {
    const requestHeaders = (options.headers ||
        new Headers({
            Accept: 'application/json',
        }));
    if (
        !requestHeaders.has('Content-Type') &&
        !(options && (!options.method || options.method === 'GET')) &&
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set('Authorization', options.user.token);
    }

    return requestHeaders;
};

export default jsonServerProvider(process.env.REACT_APP_URL_BACK, httpClient);