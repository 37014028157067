import { SimpleForm, TextInput, required, SelectInput, NumberInput, BooleanInput } from 'react-admin';
import { Currencies } from '../../Components/Enums/CurrencyEnums';
import { Grid, Typography, Divider } from "@mui/material";
import { Countries } from '../../Components/Enums/CountryEnums';
import { useFormContext } from "react-hook-form";

export default function SubscriptionPlanForm() {


    const yearAmountChanged = (amount) => {
        return amount / 12.0;
    }

    const monthAmountChanged = (amount) => {
        return amount * 12.0;
    }

    return (
        <SimpleForm redirect="list" >
            <TextInput disabled label="Identifiant" source="id" />
            <Divider fullWidth sx={{ width: "100%" }} />
             <Typography color="red">Les montants doivent être écrits avec le format suivant 29.90 Avec un . et pas une ,</Typography>
            <SelectInput label="Pays" source="subscriptionCountry" validate={required()} choices={Countries.sort(function (a, b) { return a.id.localeCompare(b.id) })} />
            <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
                    <TextInput label="Montant période d'essai" source="trialAmount" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={2}>
                    <NumberInput label="Nombre de jours période d'essai" source="trialDays" validate={required()} fullWidth />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
                    <SelectInput label="Monnaie" source="currency" validate={required()} choices={Currencies.sort(function (a, b) { return a.id.localeCompare(b.id) })} fullWidth />
                </Grid>
                <Grid item xs={2}>
                    <TextInput label="Symbole de la monnaie" source="currencySymbol" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={2}>
                    <BooleanInput label="Le symbole est avant la monnaie" source="isSymbolBefore" validate={required()} defaultValue={false} />
                </Grid>
            </Grid>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
                    <TextInput label="Montant paiement récurrent / mois" source="reccuringAmountMonth" validate={required()} fullWidth />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
                    <TextInput label="Montant paiement récurrent / an" source="reccuringAmountYear" validate={required()} fullWidth onChange={yearAmountChanged} valueToSet="reccurringAmountYearMonthDisplay" />
                </Grid>
                <Grid item xs={2}>
                    <TextInput label="Montant paiement récurrent / an sur un mois" source="reccurringAmountYearMonthDisplay" validate={required()} fullWidth onChange={monthAmountChanged} valueToSet="reccuringAmountYear" />
                </Grid>
            </Grid>
        </SimpleForm>
    )
}

export const CustomNumberInput = ({ label, source, validate, fullWidth, onChange, valueToSet }) => {

    const { setValue } = useFormContext();

    const onChangeNumber = (e) => {
        var otherValue = onChange(e.target.value);
        setValue(valueToSet, otherValue)
    }

    return <NumberInput label={label} source={source} validate={validate} fullWidth={fullWidth} onChange={onChangeNumber} />
}