export const Currencies = [
    { "id": "ALL", "name": "ALL" },
    { "id": "STN", "name": "STN" },
    { "id": "EEK", "name": "EEK" },
    { "id": "BHD", "name": "BHD" },
    { "id": "SCR", "name": "SCR" },
    { "id": "DJF", "name": "DJF" },
    { "id": "EGP", "name": "EGP" },
    { "id": "MDL", "name": "MDL" },
    { "id": "MZN", "name": "MZN" },
    { "id": "BND", "name": "BND" },
    { "id": "ZMK", "name": "ZMK" },
    { "id": "SHP", "name": "SHP" },
    { "id": "LBP", "name": "LBP" },
    { "id": "AWG", "name": "AWG" },
    { "id": "JMD", "name": "JMD" },
    { "id": "KES", "name": "KES" },
    { "id": "BYN", "name": "BYN" },
    { "id": "KHR", "name": "KHR" },
    { "id": "LAK", "name": "LAK" },
    { "id": "MVR", "name": "MVR" },
    { "id": "AOA", "name": "AOA" },
    { "id": "TJS", "name": "TJS" },
    { "id": "SVC", "name": "SVC" },
    { "id": "GNF", "name": "GNF" },
    { "id": "BRL", "name": "BRL" },
    { "id": "MOP", "name": "MOP" },
    { "id": "BOB", "name": "BOB" },
    { "id": "CDF", "name": "CDF" },
    { "id": "NAD", "name": "NAD" },
    { "id": "LYD", "name": "LYD" },
    { "id": "VUV", "name": "VUV" },
    { "id": "QAR", "name": "QAR" },
    { "id": "CLP", "name": "CLP" },
    { "id": "HRK", "name": "HRK" },
    { "id": "ISK", "name": "ISK" },
    { "id": "FKP", "name": "FKP" },
    { "id": "XCD", "name": "XCD" },
    { "id": "NOK", "name": "NOK" },
    { "id": "CUP", "name": "CUP" },
    { "id": "VND", "name": "VND" },
    { "id": "PEN", "name": "PEN" },
    { "id": "KMF", "name": "KMF" },
    { "id": "LVL", "name": "LVL" },
    { "id": "MMK", "name": "MMK" },
    { "id": "TRY", "name": "TRY" },
    { "id": "VEF", "name": "VEF" },
    { "id": "AUD", "name": "AUD" },
    { "id": "TWD", "name": "TWD" },
    { "id": "PKR", "name": "PKR" },
    { "id": "SLL", "name": "SLL" },
    { "id": "BGN", "name": "BGN" },
    { "id": "LRD", "name": "LRD" },
    { "id": "LKR", "name": "LKR" },
    { "id": "XAF", "name": "XAF" },
    { "id": "JOD", "name": "JOD" },
    { "id": "ANG", "name": "ANG" },
    { "id": "BSD", "name": "BSD" },
    { "id": "CAD", "name": "CAD" },
    { "id": "GIP", "name": "GIP" },
    { "id": "MNT", "name": "MNT" },
    { "id": "LTL", "name": "LTL" },
    { "id": "BBD", "name": "BBD" },
    { "id": "CLF", "name": "CLF" },
    { "id": "BWP", "name": "BWP" },
    { "id": "COP", "name": "COP" },
    { "id": "PHP", "name": "PHP" },
    { "id": "HUF", "name": "HUF" },
    { "id": "FJD", "name": "FJD" },
    { "id": "MWK", "name": "MWK" },
    { "id": "THB", "name": "THB" },
    { "id": "XPF", "name": "XPF" },
    { "id": "RSD", "name": "RSD" },
    { "id": "SAR", "name": "SAR" },
    { "id": "UYU", "name": "UYU" },
    { "id": "BZD", "name": "BZD" },
    { "id": "SYP", "name": "SYP" },
    { "id": "GMD", "name": "GMD" },
    { "id": "SZL", "name": "SZL" },
    { "id": "SBD", "name": "SBD" },
    { "id": "ETB", "name": "ETB" },
    { "id": "CHF", "name": "CHF" },
    { "id": "MXN", "name": "MXN" },
    { "id": "ARS", "name": "ARS" },
    { "id": "GTQ", "name": "GTQ" },
    { "id": "GHS", "name": "GHS" },
    { "id": "NIO", "name": "NIO" },
    { "id": "JPY", "name": "JPY" },
    { "id": "BDT", "name": "BDT" },
    { "id": "UZS", "name": "UZS" },
    { "id": "SOS", "name": "SOS" },
    { "id": "BTN", "name": "BTN" },
    { "id": "NZD", "name": "NZD" },
    { "id": "TZS", "name": "TZS" },
    { "id": "IQD", "name": "IQD" },
    { "id": "MGA", "name": "MGA" },
    { "id": "DZD", "name": "DZD" },
    { "id": "GYD", "name": "GYD" },
    { "id": "USD", "name": "USD" },
    { "id": "KWD", "name": "KWD" },
    { "id": "CNY", "name": "CNY" },
    { "id": "PYG", "name": "PYG" },
    { "id": "SGD", "name": "SGD" },
    { "id": "KZT", "name": "KZT" },
    { "id": "PGK", "name": "PGK" },
    { "id": "AMD", "name": "AMD" },
    { "id": "GBP", "name": "GBP" },
    { "id": "AFN", "name": "AFN" },
    { "id": "CRC", "name": "CRC" },
    { "id": "XOF", "name": "XOF" },
    { "id": "YER", "name": "YER" },
    { "id": "MRU", "name": "MRU" },
    { "id": "DKK", "name": "DKK" },
    { "id": "TOP", "name": "TOP" },
    { "id": "INR", "name": "INR" },
    { "id": "SDG", "name": "SDG" },
    { "id": "DOP", "name": "DOP" },
    { "id": "ZWL", "name": "ZWL" },
    { "id": "UGX", "name": "UGX" },
    { "id": "SEK", "name": "SEK" },
    { "id": "LSL", "name": "LSL" },
    { "id": "MYR", "name": "MYR" },
    { "id": "TMT", "name": "TMT" },
    { "id": "OMR", "name": "OMR" },
    { "id": "BMD", "name": "BMD" },
    { "id": "KRW", "name": "KRW" },
    { "id": "HKD", "name": "HKD" },
    { "id": "KGS", "name": "KGS" },
    { "id": "BAM", "name": "BAM" },
    { "id": "NGN", "name": "NGN" },
    { "id": "ILS", "name": "ILS" },
    { "id": "MUR", "name": "MUR" },
    { "id": "RON", "name": "RON" },
    { "id": "TND", "name": "TND" },
    { "id": "AED", "name": "AED" },
    { "id": "PAB", "name": "PAB" },
    { "id": "NPR", "name": "NPR" },
    { "id": "TTD", "name": "TTD" },
    { "id": "RWF", "name": "RWF" },
    { "id": "HTG", "name": "HTG" },
    { "id": "IDR", "name": "IDR" },
    { "id": "EUR", "name": "EUR" },
    { "id": "KYD", "name": "KYD" },
    { "id": "IRR", "name": "IRR" },
    { "id": "KPW", "name": "KPW" },
    { "id": "MKD", "name": "MKD" },
    { "id": "SRD", "name": "SRD" },
    { "id": "HNL", "name": "HNL" },
    { "id": "AZN", "name": "AZN" },
    { "id": "ERN", "name": "ERN" },
    { "id": "CZK", "name": "CZK" },
    { "id": "CVE", "name": "CVE" },
    { "id": "BIF", "name": "BIF" },
    { "id": "MAD", "name": "MAD" },
    { "id": "RUB", "name": "RUB" },
    { "id": "UAH", "name": "UAH" },
    { "id": "WST", "name": "WST" },
    { "id": "PLN", "name": "PLN" },
    { "id": "ZAR", "name": "ZAR" },
    { "id": "GEL", "name": "GEL" },
    { "id": "ZMW", "name": "ZMW" },
];